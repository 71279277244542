import React, { useState } from "react"
import styled from 'styled-components'
import { Link, navigate } from "gatsby"
import { isMobile } from "react-device-detect";

import Layout2Columns from "../layout/Layout2Columns";
import TextField from "../fields/TextField"
import BlueButton from "../buttons/BlueButton"

import MapImage from "../images/map-preview"

const MapLink = styled(Link)`
    margin: ${isMobile ? "25px auto 0px auto":"0px"};
`
const MapGrid = styled.div`
    height: 200px;
    overflow: hidden;
`
const PromptGrid = styled.div`
    text-align: center;
    padding: 10px;

    h2 {
        font-size: 20px;
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
    }
`

function MapWidget(props) {
    const [zipCode, setZipCode] = useState("");
      
    function onZip() {
        navigate("/map/?zipcode=" + zipCode);
    }
    function onZipCode(e) {
        setZipCode(e.target.value);
    }

    return (
        <div style={{padding: "15px"}}>
            <Layout2Columns>
                <PromptGrid>
                    <h2>Find a SignMonkey Sign Near You</h2>
                    <p>
                        See where some of our signs are installed.
                        There is probably one near you.
                    </p>

                    <div>
                        <TextField 
                            name="zip" 
                            label="Zip Code"
                            value={ zipCode } 
                            wide
                            onChange={onZipCode} 
                        >
                            <BlueButton onClick={onZip} label="Find Signs" />
                        </TextField>
                    </div>
                </PromptGrid>
                <MapGrid>
                    <MapLink to="/map/">
                        <MapImage />
                    </MapLink>
                </MapGrid>
            </Layout2Columns>
        </div>
    );
}

export default MapWidget